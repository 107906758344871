import "formdata-polyfill";
import "whatwg-fetch";
import Bugsnag from "@bugsnag/js";

var fromIdInt = 100002;
// event date to
var toIdInt = 100003;
// all dat event
var allDayIdInt = 100001;

// event date from
var fromId = "" + fromIdInt;
// event date to
var toId = "" + toIdInt;
// all dat event
var allDayId = "" + allDayIdInt;

let baseUrl = document.getElementById("customFormBaseUrl").value;
let formAccessToken = document.getElementById("formAccessToken").value;
let previewElement = document.getElementById("previewTimestamp");
let previewTimestamp = previewElement == null ? null : previewElement.value;

let dateTimeSupported = false;
let dateSupported = false;
var yearSelect = null;
var monthSelect = null;
var daySelect = null;
var hourSelect = null;
var minuteSelect = null;
var legacyDateTimeFields = [];
var legacyDateFields = [];
var contentId = "CC9B39265B604255A35DA6F3A61F41D4";
let requiredFieldPresent = false;
let recaptchaRequired = false;

var requiredFields = [];

var submitButtonText = "";
var standardFieldIds = [];
var standardRFPFieldIds = [];
var rfpMealFieldId = null;

var dropdownDependencies = [];
var originalForm = null;
var customMultiSelectIds = [];

function findInChildren(source, dependencyId) {
  var found = null;
  var max = source.ChildFields.length;
  var count = 0;

  while (count < max && !found) {
    var candidate = source.ChildFields[count];
    if (candidate.Id === dependencyId) {
      found = candidate;
    }
    count++;
  }

  return found;
}

function findFormObject(dependencyId) {
  var found = null;
  var max = originalForm.Form.length;
  var count = 0;

  while (count < max && !found) {
    var candidate = originalForm.Form[count];
    if (candidate.Id === dependencyId) {
      found = candidate;
    } else if (candidate.ChildFields) {
      found = findInChildren(candidate, dependencyId);
    }
    count++;
  }

  return found;
}

function getDependentOptions(dependencyId, parentValue) {
  var options = [];
  var dependentFormObject = findFormObject(dependencyId);
  if (dependentFormObject && dependentFormObject.Options) {
    for (var i = 0; i < dependentFormObject.Options.length; i++) {
      var candidate = dependentFormObject.Options[i];
      if (
        candidate &&
        candidate.DependentOn &&
        candidate.DependentOn === parentValue
      ) {
        options.push(candidate);
      }
    }
  }
  return options;
}

function removeOptions(selectElement) {
  var i,
    L = selectElement.options.length - 1;
  for (i = L; i >= 0; i--) {
    selectElement.remove(i);
  }
}

function addOptions(element, options, defaultValue) {
  for (var i = 0; i < options.length; i++) {
    var opt = document.createElement("option");
    opt.value = options[i].Value;
    opt.innerHTML =
      options[i].DisplayName && options[i].DisplayName.length > 0
        ? options[i].DisplayName
        : options[i].Name;
    if (defaultValue && options[i].Value === defaultValue) {
      opt.selected = true;
    }
    element.appendChild(opt);
  }
}

function findDefaultValue(id) {
  var found = null;
  var targetId = "" + id;

  if (originalForm.Defaults) {
    var max = originalForm.Defaults.length;

    var count = 0;

    while (count < max && !found) {
      var candidate = originalForm.Defaults[count];
      if (candidate && candidate.Name === targetId) {
        found = candidate.Value;
      }

      count++;
    }
  }

  return found;
}

// idOfChangedControl is an "int"
function dropdownchange(idOfChangedControl) {
  /*
          {
              parent: formObject.DependentOn, // (int) When this changes
              dependency: id                  // (int) Reload the values for this
          }
      */

  var found = null;
  var max = dropdownDependencies.length;
  var count = 0;
  while (count < max && !found) {
    var candidate = dropdownDependencies[count];
    if (candidate.parent === idOfChangedControl) {
      found = candidate;
    }
    count++;
  }

  if (found) {
    // Find the correct values from the initial JSON object
    var parentControlId = "" + found.parent;
    var dependentControlId = "" + found.dependency;

    // Get the parent control value
    var parentValue = null;
    var parentElement = document.getElementById(parentControlId);
    if (!parentElement) {
      // Check defaults for parent value
      parentValue = findDefaultValue(parentControlId);
    } else {
      parentValue = parentElement.value;
    }

    if (parentValue) {
      var dependentElement = document.getElementById(dependentControlId);

      // Get the range of dependent values from the "originalForm" object
      var values = getDependentOptions(found.dependency, parentValue);

      // Clear the current dependent control values
      removeOptions(dependentElement);

      // Find a default value if it exists
      var defaultValue = findDefaultValue(dependentControlId);

      // Add the new dependent control values
      addOptions(dependentElement, values, defaultValue);
    }
  }
}

function initialLoadDropdownsInGroup(source) {
  for (var i = 0; i < source.length; i++) {
    var formObject = source[i];
    if (formObject.ControlType === "DropDown") {
      var elementId = "" + formObject.Id;
      var defaultValue = findDefaultValue(elementId);

      if (defaultValue && defaultValue.length > 0) {
        var element = document.getElementById(elementId);
        element.value = defaultValue;
      }
    }
  }

  // Run each dependency in turn
  for (var i = 0; i < dropdownDependencies.length; i++) {
    dropdownchange(dropdownDependencies[i].parent);
  }
}

function initialLoadDropdowns() {
  // Set them all to defaults
  for (var i = 0; i < originalForm.Form.length; i++) {
    var formObject = originalForm.Form[i];
    if (formObject.ControlType === "DropDown") {
      var elementId = "" + formObject.Id;
      var defaultValue = findDefaultValue(elementId);

      if (defaultValue && defaultValue.length > 0) {
        var element = document.getElementById(elementId);
        element.value = defaultValue;
      }
    } else if (formObject.ControlType === "Group") {
      initialLoadDropdownsInGroup(formObject.ChildFields);
    }
  }

  // Run each dependency in turn
  for (var i = 0; i < dropdownDependencies.length; i++) {
    dropdownchange(dropdownDependencies[i].parent);
  }
}

function showNotAvailableError() {
  document.getElementById(
    contentId
  ).innerHTML = `<div class="not-available-error">${originalForm.Translations.NotAvailable}</div>`;
}

function doLoad(jsonObject) {
  originalForm = jsonObject;
  document.getElementById(
    contentId
  ).innerHTML = `<input type="datetime-local" id="dateTimeTest" hidden><input type="date" id="dateTest" hidden>`;

  recaptchaRequired = jsonObject.RecaptchaEnabled;
  if (recaptchaRequired === true) {
    var header = document.getElementsByTagName("head")[0];
    var grecaptchaScript = document.createElement("script");
    grecaptchaScript.type = "text/javascript";
    grecaptchaScript.src = "https://www.google.com/recaptcha/api.js";
    grecaptchaScript.async = true;
    grecaptchaScript.defer = true;
    header.appendChild(grecaptchaScript);
  }

  let formData = `<input type="hidden" id="lookupIdentifier" name="lookupIdentifier" value="${jsonObject.LookupIdentifier}">`;
  dateTimeSupported = checkDateTime();
  dateSupported = checkDate();

  Bugsnag.start("b1ba3a262450c3875c921f1afc5207f7");

  for (let formObject of jsonObject.Form) {
    let id = formObject.Id;
    if (formObject.ControlType === "RichText") {
        formData += renderRichText(formObject);
    } else if (formObject.ControlType === "Group") {
      let groupContent = `<div class="custom-group"><span class="custom-group-label">${formObject.Name}</span>`;
      for (var i = 0; i < formObject.ChildFields.length; i++) {
        groupContent += `<div class="row"><div class="col">`;
        let childObject = formObject.ChildFields[i];

        if (childObject.ControlType === "RichText") {
            groupContent += renderRichText(childObject);
        }
        else if (childObject.ControlType === "Recaptcha") {
          if (recaptchaRequired === true) {
            var siteKey = jsonObject.RecaptchaSiteKey;
            groupContent += `<div class="g-recaptcha" data-sitekey="${siteKey}" data-callback="enableSubmit"></div>`;
          }
        } else if (childObject.ControlType === "Submit") {
          for (var k = 0; k < childObject.Properties.length; k++) {
            if (childObject.Properties[k].Id === "ButtonText") {
              submitButtonText = childObject.Properties[k].Value;
            }
          }
          groupContent += `<div class="row"><div class="col"><button type="button" id="btnSubmit" disabled class="btn">${submitButtonText}</button></div></div>`;
        } else {
          groupContent += `<div class="form__label form__label-${
            childObject.Id
          }${requiredLabelClass(childObject)}"> ${buildLabel(
            childObject.Id,
            childObject
          )}</div>`;
          groupContent += `<div class="input-field input-field-${
            childObject.Id
          }"> ${buildInput(
            childObject.Id,
            childObject
          )} <span class="validation-message" id="${
            childObject.Id
          }-validation" hidden></span></div>`;
          groupContent += buildInstructions(childObject);
          if (childObject.IsStandard === true) {
            standardFieldIds.push(childObject.Id);
          }
          if (childObject.IsRfp === true) {
            standardRFPFieldIds.push(childObject.Id);
          }
        }
        groupContent += `</div></div>`;
      }
      groupContent += `</div>`;
      formData += groupContent;
    } else if (formObject.ControlType !== "Group") {
      if (formObject.ControlType === "Recaptcha") {
        if (recaptchaRequired === true) {
          var siteKey = jsonObject.RecaptchaSiteKey;
          formData += `<div class="g-recaptcha" data-sitekey="${siteKey}" data-callback="enableSubmit"></div>`;
        }
      } else if (formObject.ControlType === "Submit") {
        for (var k = 0; k < formObject.Properties.length; k++) {
          if (formObject.Properties[k].Id === "ButtonText") {
            submitButtonText = formObject.Properties[k].Value;
          }
        }
        formData += `<div class="row"><div class="col"><button type="button" id="btnSubmit" disabled class="btn">${submitButtonText}</button></div></div>`;
      } else {
        // Do not show non-Visible Distribution Lists
        let displayMode = "";
        if (
          ((formObject.ControlType === "DistributionList" ||
            formObject.ControlType === "InventoryList") &&
            !formObject.Visible) ||
          (formObject.Id === 177 && formObject.Hidden)
        ) {
          // Country can be hidden!
          displayMode = "display: none;";
        }

        formData += `<div class="row" style="${displayMode}"><div class="col">`;
        formData += `<div class="form__label form__label-${id}${requiredLabelClass(
          formObject
        )}"> ${buildLabel(id, formObject)} </div>`;
        formData += `<div class="input-field input-field-${id}"> ${buildInput(
          id,
          formObject
        )} <span class="validation-message" id="${id}-validation" hidden></span></div>`;

        formData += buildInstructions(formObject);
        formData += "</div></div>";

        if (formObject.IsStandard === true) {
          standardFieldIds.push(id);
        }
        if (formObject.IsRfp === true) {
          standardRFPFieldIds.push(id);
        }
      }
    }
  }

  if (requiredFieldPresent === true) {
      formData += `<span class="required-marker">* ${originalForm.Translations.Required}</span>`;
  }

  document.getElementById(
    contentId
  ).innerHTML = `<input type="datetime-local" id="dateTimeTest" hidden> <form id="idssForm" class="form">${formData}</form>`;

  const idssForm = document.querySelector("#idssForm");
  const formButton = document.querySelector("#btnSubmit");
  if (idssForm) {
    idssForm.addEventListener("submit", function (e) {
      submitTheForm(e, this);
      return false;
    });
  }
  if (formButton) {
    formButton.addEventListener("click", function (e) {
      submitTheForm(e, idssForm);
      return false;
    });
  }

  onLoadCyclone();
}

function renderRichText(formObject) {
    var encodedHtml = "";
    for (var i = 0; i < formObject.Properties.length; i++) {
        if (formObject.Properties[i].Id === "RichTextContent") {
            encodedHtml = formObject.Properties[i].Value;
        }
    }
    var fieldData = '<div class="form__text">';
    var htmlContent = window.atob(encodedHtml);
    fieldData += htmlContent;
    fieldData += "</div>";

    return fieldData;
}

function enableSubmit() {
  var button = document.getElementById("btnSubmit");
  button.disabled = false;
}

function getTheForm() {
    let fetchUrl = `${baseUrl}api/form?accessToken=${formAccessToken}`;

    if (previewTimestamp != null) {
        fetchUrl += `&previewTimestamp=${previewTimestamp}`
    }

  var jsonPromise = performGetHttpRequest(fetchUrl);

  jsonPromise
    .then((value) => {
      if (value.data.NotAvailable) {
        showNotAvailableError();
      } else {
        var jsonObject = value.data;
        doLoad(jsonObject);
      }
    })
    .catch((err) => {
      console.error("Error at getting the form: " + err);
    });
}

function resetForm() {
  const btnSubmit = document.getElementById("btnSubmit");
  btnSubmit.textContent = submitButtonText;

  enableSubmit();

  if (
    recaptchaRequired === true &&
    grecaptcha !== undefined &&
    grecaptcha !== null
  ) {
    grecaptcha.reset();
  }
}

async function submitTheForm(e, form) {
  // Prevent page reload
  e.preventDefault();
  // Disable submit button
  const btnSubmit = document.getElementById("btnSubmit");
    btnSubmit.textContent = originalForm.Translations.ButtonSubmitting;
  btnSubmit.disabled = true;

  if (validateForm(form)) {
  //if (true) {
    let fetchUrl = `${baseUrl}api/submit`;

    // Build Json
    const jsonFormData = await buildJsonFormData(form);

      if (eventDatesAreValid(jsonFormData) && listsAreValid(form, jsonFormData)) {
      // Do Post
      const response = await performPostHttpRequest(fetchUrl, jsonFormData);
      if (response.success === true) {
        var responseData = JSON.parse(response.data);
        if (responseData.UseUrl) {
          window.location.replace(responseData.RedirectUrl);
        } else {
          document.getElementById(
            contentId
          ).innerHTML = `${responseData.CustomMessage}`;
        }
      } else {
        var message = "An error occurred";
        if ((response.error !== null) & (response.error !== undefined)) {
          message = response.error[0];
        }
        alert(message);
        resetForm();
      }
    } else {
      // Event dates not valid - do nothing
      resetForm();
    }
  } else {
    resetForm();
  }
}

async function performPostHttpRequest(fetchUrl, body) {
  try {
    const rawResponse = await fetch(fetchUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(body),
    });
    const content = await rawResponse.json();
    return content;
  } catch (err) {
    console.error("Error at fetch POST: " + err);
  }
}

async function performGetHttpRequest(fetchUrl) {
  try {
    const rawResponse = await fetch(fetchUrl);
    const content = await rawResponse.json();
    return content;
  } catch (err) {
    console.error("Error at fetch GET: " + err);
    throw err;
  }
}

/* Builders */
function buildLabel(id, formObject) {
  if (
    formObject.ControlType === "DistributionList" ||
    formObject.ControlType === "InventoryList" ||
    formObject.ControlType === "ConnectionItemList"
  ) {
      return `<label for=${id}>${formObject.ListName}</label>
      ${checkRequiredForLabel(
          formObject
      )}`;
  } else {
    var label = "";
    for (var i = 0; i < formObject.Properties.length; i++) {
      if (formObject.Properties[i].Id === "Label") {
        label = formObject.Properties[i].Value;
      }
    }
    if (label === null || label === undefined || label.length <= 0) {
      label = formObject.Name;
    }
    return `<label for=${id}>${label}</label>${checkRequiredForLabel(
      formObject
    )}`;
  }
}

function buildOptions(formObject) {
  if (formObject.Options) {
    var text = "";
    for (var i = 0; i < formObject.Options.length; i++) {
      var name = formObject.Options[i].Name;
      var value = formObject.Options[i].Value;
      text += "<option value='" + value + "'>" + name + "</option>";
    }
    return text;
  } else {
    return "";
  }
}

function checkBoxClicked(source) {
  // if this is all day, then update the from and to controls
  if (source.id === allDayId) {
    var sourceType = source.checked ? "datetime-local" : "date";
    var targetType = source.checked ? "date" : "datetime-local";
    var fromControl = document.getElementById(fromId);
    var toControl = document.getElementById(toId);
    if (fromControl && fromControl.type === sourceType) {
      fromControl.type = targetType;
    }
    if (toControl && toControl.type === sourceType) {
      toControl.type = targetType;
    }
  }
}

function buildInput(id, formObject) {
  switch (formObject.ControlType) {
    case "TextBox":
    case "Text":
      return `<input class="input-text" type="text" id=${id} name=${id} ${checkRequired(
        formObject,
        id
      )} ${checkPlaceholder(formObject)}>`;

    case "Numeric":
      return `<input class="input-numeric" type="number" id=${id} name=${id} step="1" min="0" value="0" ${checkRequired(
        formObject,
        id
      )} ${checkPlaceholder(formObject)}>`;

    case "Decimal":
      return `<input class="input-numeric" type="number" id=${id} name=${id} step="0.01" min="0.00" value="0.00" ${checkRequired(
        formObject,
        id
      )} ${checkPlaceholder(formObject)}>`;

    case "CheckBox":
      return `<input class="input-checkbox" type="checkbox" id=${id} name=${id} onclick="checkBoxClicked(this)" ${checkRequired(
        formObject,
        id
      )}>`;

    case "File":
      return `<input class="input-file" type="file" id=${id} name=${id} ${checkRequired(
        formObject,
        id
      )}>`;

    case "InquiryRFPFiles":
      return `<input class="input-file" type="file" id=${id} name=${id} multiple ${checkRequired(
        formObject,
        id
      )}>`;

    case "DropDown":
      if (formObject.DependentOn) {
        dropdownDependencies.push({
          parent: formObject.DependentOn,
          dependency: id,
        });
      }
      var optionsText = buildOptions(formObject);
      return `<select class="input-dropdown" id=${id} name=${id} onchange="dropdownchange(${id});" ${checkRequired(
        formObject,
        id
      )}>${optionsText}</select>`;

    case "DropDownLink":
      var optionsText = buildOptions(formObject);
      return `<select class="input-dropdown" id=${id} name=${id} ${checkRequired(
        formObject,
        id
      )}>${optionsText}</select>`;

    case "ListBox":
      var optionsText2 = buildOptions(formObject);
      return `<select class="input-listbox" id=${id} name=${id} multiple ${checkRequired(
        formObject,
        id
      )}>${optionsText2}</select>`;
    case "ListBoxMulti":
      if (!formObject.DisplayType || formObject.DisplayType === "ListBox") {
        var optionsText2 = buildOptions(formObject);
        return `<select class="input-listbox" id=${id} name=${id} multiple ${checkRequired(
          formObject,
          id
        )}>${optionsText2}</select>`;
      } else {
        // Use checkboxes
        return buildDropDownListAsCheckboxes(id, formObject);
      }

    case "TextArea":
    case "Html":
      return `<textarea class="input-textarea" form="idssForm" id=${id} name=${id} rows="5" ${checkRequired(
        formObject,
        id
      )} ${checkPlaceholder(formObject)}></textarea>`;

    case "DateTime":
      return `${buildDateTime(dateTimeSupported, id, formObject)}`;

    case "Date":
      return `${buildDate(dateSupported, id, formObject)}`;

    case "TextLink":
      return `<input class="input-email" type="text" id=${id} name=${id} ${checkRequired(
        formObject,
        id
      )} ${checkPlaceholder(formObject)}>`;

    case "ReadOnlyLabel":
    case "InquiryEmailer":
    case "InquiryReferrals":
      return `<span class="read-only-label" id=${id} name=${id} ${checkRequired(
        formObject,
        id
      )}>${formObject.Name}</span>`;

    case "DateRange":
      var part2Id = id + 0.2;
      return `<div class="date-range-container"><span class="from-date-range">${originalForm.Translations.DateRangeFrom}</span><input class="input-date" type="date" id=${id} name=${id} ${checkRequired(
        formObject,
        id
      )}><span class="to-date-range">${originalForm.Translations.DateRangeTo}</span><input class="input-date" type="date" id=${part2Id} name=${part2Id} ${checkRequired(
        formObject,
        id
      )}></div>`;

    case "DistributionList":
      return buildDistributionList(id, formObject);

    case "InventoryList":
      return buildInventoryList(id, formObject);
    case "ConnectionItemList":
      return buildConnectionItemList(id, formObject);

    case "InquiryRFPMeals":
      return buildRFPMealInput(id, formObject);
  }

  return `<div>${originalForm.Translations.UnknownFieldType}</div>`;
}

function buildDistributionList(id, formObject) {
  var isChecked = formObject.Visible ? "" : "checked";
  var html = `<div class='distribution-list-container'>`;

  // Show all
  var optionCount = formObject.Options.length;
  if (optionCount === 1) {
    var nextOption = formObject.Options[0];
    var displayName = nextOption.DisplayName;
    if (
      displayName === null ||
      displayName === undefined ||
      displayName.length <= 0
    ) {
      displayName = nextOption.Name;
    }
    var optionId = `list-option-${id}-${nextOption.Value}`;
    html += "<div class='distribution-list-option'>";
    html += `   <input type="checkbox" id="${optionId}" class="distribution-list-checkbox" name="${optionId}" ${isChecked} />`;
    html += `   <label for="${optionId}" class="distribution-list-label">${displayName}</label>`;
    html += "</div>";
  } else {
    for (var i = 0; i < optionCount; i++) {
      var nextOption = formObject.Options[i];

      var displayName = nextOption.DisplayName;
      if (
        displayName === null ||
        displayName === undefined ||
        displayName.length <= 0
      ) {
        displayName = nextOption.Name;
      }

      var optionId = `list-option-${id}-${nextOption.Value}`;
      html += "<div class='distribution-list-option'>";
      html += `   <input type="checkbox" id="${optionId}" class="distribution-list-checkbox" name="${optionId}" ${isChecked} />`;
      html += `   <label for="${optionId}" class="distribution-list-label">${displayName}</label>`;
      html += "</div>";
    }
  }
  html += "</div>";

  return html;
}

function buildInventoryList(id, formObject) {
  var isChecked = formObject.Visible ? "" : "checked";
  var html = `<div class='checkbox-list-container'>`;

  // Show all
  var optionCount = formObject.Options.length;
  for (var i = 0; i < optionCount; i++) {
    var nextOption = formObject.Options[i];
    var displayName = nextOption.DisplayName;
    if (
      displayName === null ||
      displayName === undefined ||
      displayName.length <= 0
    ) {
      displayName = nextOption.Name;
    }

    var optionId = `list-option-${id}-${i}`;
    var optionValue = `list-option-${id}-${nextOption.Value}`;

    html += "<div class='checkbox-list-option'>";
    html += `   <input type="checkbox" id="${optionId}" class="checkbox-list-checkbox" name="${optionId}" ${isChecked} data-realvalue="${optionValue}" />`;
    html += `   <label for="${optionId}" class="checkbox-list-label">${displayName}</label>`;
    html += "</div>";
  }

  html += "</div>";

  return html;
}

function isNullOrWhitespace(candidate) {
  if (!candidate) return true;
  return candidate.trim().length === 0;
}

function buildConnectionItemList(id, formObject) {
  var isChecked = formObject.Visible ? "" : "checked";
  var html = `<div class='checkbox-list-container'>`;

  // Do the sort
  let clone = JSON.parse(JSON.stringify(formObject.Options));
  clone.sort((a, b) => {
    let nameA = a.TopicName.toUpperCase(); // ignore upper and lowercase
    let nameB = b.TopicName.toUpperCase(); // ignore upper and lowercase

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // Same topic
    if (a.SortOrder < b.SortOrder) return -1;
    if (a.SortOrder > b.SortOrder) return 1;

    // Same sort order - default to alpha
    nameA = isNullOrWhitespace(a.DisplayName)
      ? isNullOrWhitespace(a.Name)
        ? ""
        : a.Name
      : a.DisplayName;
    nameB = isNullOrWhitespace(b.DisplayName)
      ? isNullOrWhitespace(b.Name)
        ? ""
        : b.Name
      : b.DisplayName;
    nameA = nameA.toUpperCase(); // ignore upper and lowercase
    nameB = nameB.toUpperCase(); // ignore upper and lowercase

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  // Show all
  var optionCount = clone.length;
  var currentTopic = "";
  for (var i = 0; i < optionCount; i++) {
    var nextOption = clone[i];
    var displayName = nextOption.DisplayName;
    if (
      displayName === null ||
      displayName === undefined ||
      displayName.length <= 0
    ) {
      displayName = nextOption.Name;
    }

    var optionId = `list-option-${id}-${i}`;
    var optionValue = `list-option-${id}-${nextOption.Value}`;

    var nextTopic = nextOption.TopicName;
    if (currentTopic !== nextTopic) {
      currentTopic = nextTopic;
      html += "<div class='connection-list-item-topic'>";
      html += nextTopic;
      html += "</div>";
    }

    html += "<div class='checkbox-list-option'>";
    html += `   <input type="checkbox" id="${optionId}" class="checkbox-list-checkbox" name="${optionId}" ${isChecked} data-realvalue="${optionValue}" />`;
    html += `   <label for="${optionId}" class="checkbox-list-label">${displayName}</label>`;
    html += "</div>";
  }

  html += "</div>";

  return html;
}

function buildDropDownListAsCheckboxes(id, formObject) {
  var isChecked = false;
  var html = `<div class='checkbox-list-container'>`;

  // Show all
  var optionCount = formObject.Options.length;
  for (var i = 0; i < optionCount; i++) {
    var nextOption = formObject.Options[i];
    var displayName = nextOption.DisplayName;
    if (
      displayName === null ||
      displayName === undefined ||
      displayName.length <= 0
    ) {
      displayName = nextOption.Name;
    }

    var optionId = `list-option-${id}-${i}`;
    var optionValue = `list-option-${id}-${nextOption.Value}`;

    customMultiSelectIds.push(id);

    html += "<div class='checkbox-list-option'>";
    html += `   <input type="checkbox" id="${optionId}" class="checkbox-list-checkbox" name="${optionId}" ${isChecked} data-realvalue="${optionValue}" />`;
    html += `   <label for="${optionId}" class="checkbox-list-label">${displayName}</label>`;
    html += "</div>";
  }

  html += "</div>";

  return html;
}

function buildRFPMealInput(id, formObject) {
  rfpMealFieldId = id;
  return `<div className="rfp-meals">
                <span>${originalForm.Translations.RfpBreakfast}</span>
                <input id="${id}-Breakfast" type="checkbox" className="rfp-meals-checkbox"></input>
                <span>${originalForm.Translations.RfpLunch}</span>
                <input id="${id}-Lunch" type="checkbox" className="rfp-meals-checkbox"></input>
            </div>

            <div className="rfp-meals">
                <span>${originalForm.Translations.RfpDinner}</span>
                <input id="${id}-Dinner" type="checkbox" className="rfp-meals-checkbox"></input>
                <span>${originalForm.Translations.RfpSnacks}</span>
                <input id="${id}-Snacks" type="checkbox" className="rfp-meals-checkbox"></input>
            </div>`;
}

function buildDateTime(supported, id, formObject) {
  if (supported) {
    return `<input class="input-datetime" type="datetime-local" id=${id} name=${id} ${checkRequired(
      formObject,
      id
    )}>`;
  } else {
    legacyDateTimeFields.push(id);
    return `<div id="${id}-fallbackDateTimePicker">
        <div>
          <span>
            <label for="${id}-day">Day:</label>
            <select id="${id}-day" name="${id}-day" onchange="dayChange(this)">
            </select>
          </span>
          <span>
            <label for="${id}-month">Month:</label>
            <select id="${id}-month" name="${id}-month" onchange="dateChange(this)">
              <option selected>January</option>
              <option>February</option>
              <option>March</option>
              <option>April</option>
              <option>May</option>
              <option>June</option>
              <option>July</option>
              <option>August</option>
              <option>September</option>
              <option>October</option>
              <option>November</option>
              <option>December</option>
            </select>
          </span>
          <span>
            <label for="${id}-year">Year:</label>
            <select id="${id}-year" name="${id}-year" onchange="dateChange(this)">
            </select>
          </span>
        </div>
        <div>
          <span>
            <label for="${id}-hour">Hour:</label>
            <select id="${id}-hour" name="${id}-hour">
            </select>
          </span>
          <span>
            <label for="${id}-minute">Minute:</label>
            <select id="${id}-minute" name="${id}-minute">
            </select>
          </span>
        </div>
      </div>`;
  }
}
function buildDate(supported, id, formObject) {
  if (supported) {
    return `<input class="input-date" type="date" id=${id} name=${id} ${checkRequired(
      formObject,
      id
    )}>`;
  } else {
    legacyDateFields.push(id);
    return `<div id="${id}-fallbackDateTimePicker">
        <div>
          <span>
            <label for="${id}-day">Day:</label>
            <select id="${id}-day" name="${id}-day" onchange="dayChange(this)">
            </select>
          </span>
          <span>
            <label for="${id}-month">Month:</label>
            <select id="${id}-month" name="${id}-month" onchange="dateChange(this)">
              <option selected>January</option>
              <option>February</option>
              <option>March</option>
              <option>April</option>
              <option>May</option>
              <option>June</option>
              <option>July</option>
              <option>August</option>
              <option>September</option>
              <option>October</option>
              <option>November</option>
              <option>December</option>
            </select>
          </span>
          <span>
            <label for="${id}-year">Year:</label>
            <select id="${id}-year" name="${id}-year" onchange="dateChange(this)">
            </select>
          </span>
        </div>
      </div>`;
  }
}

async function buildJsonFormData(form) {
  const jsonFormData = [];
  var grecaptchaResponse = "";
  var processedLegacyDateTimeFields = [];
  var distributionLists = [];
  var inventoryItems = [];
  var connectionItems = [];
  var geoCodes = [];
  var eventCategories = [];
  var customMultiSelections = [];

  for (var i = 0; i < form.elements.length; i++) {
    var field = form.elements[i];
    var innerJson = {};
    let isStandardField = false;
    let isRfpField = false;

    if (standardFieldIds.indexOf(Math.floor(parseFloat(field.id))) > -1) {
      isStandardField = true;
    }
    if (standardRFPFieldIds.indexOf(Math.floor(parseFloat(field.id))) > -1) {
      isRfpField = true;
    }

    switch (field.type) {
      case "text":
      case "email":
      case "textarea":
      case "number":
        if (field.name === "g-recaptcha-response") {
          grecaptchaResponse = field.value;
        } else {
          var id = parseInt(field.id);
          innerJson = {
            Id: id,
            Value: field.value,
            IsStandard: isStandardField,
            IsRfp: isRfpField,
          };
          jsonFormData.push(innerJson);
        }
        break;
      case "file":
        var id2 = parseInt(field.id);
        for (var j = 0; j < field.files.length; j++) {
          var file = field.files[j];
          if (file) {
            var base64 = await toBase64(file);
            innerJson = {
              Id: id2,
              SubId: j.toString(),
              Value: base64,
              DataName: file.name,
              IsStandard: isStandardField,
              IsRfp: isRfpField,
            };
            jsonFormData.push(innerJson);
          }
        }
        break;
      case "select-one":
        // Check if this dropdown is part of a Legacy Date input set of dropdowns
        var datePickerElementId = parseFloat(
          field.id.substr(0, field.id.indexOf("-"))
        );
        if (legacyDateTimeFields.indexOf(datePickerElementId) > -1) {
          // If this field is a legacy datetime field and has not already been processed.
          if (
            processedLegacyDateTimeFields.indexOf(datePickerElementId) === -1
          ) {
            var id3 = parseFloat(datePickerElementId);
            getDateElements(id3);
            innerJson = {
              Id: Math.floor(id3),
              SubId: datePickerElementId,
              Value: `${
                yearSelect.options[yearSelect.selectedIndex].text
              }-${getMonthFromString(
                monthSelect.options[monthSelect.selectedIndex].text
              )}-${daySelect.options[daySelect.selectedIndex].text}T${
                hourSelect.options[hourSelect.selectedIndex].text
              }:${minuteSelect.options[minuteSelect.selectedIndex].text}`,
            };
            processedLegacyDateTimeFields.push(id3);
            jsonFormData.push(innerJson);
          }
        } else if (legacyDateFields.indexOf(datePickerElementId) > -1) {
          // If this field is a legacy datetime field and has not already been processed.
          if (
            processedLegacyDateTimeFields.indexOf(datePickerElementId) === -1
          ) {
            var id3 = parseFloat(datePickerElementId);
            getDateElements(id3);
            innerJson = {
              Id: Math.floor(id3),
              SubId: datePickerElementId,
              Value: `${
                yearSelect.options[yearSelect.selectedIndex].text
              }-${getMonthFromString(
                monthSelect.options[monthSelect.selectedIndex].text
              )}-${daySelect.options[daySelect.selectedIndex].text}`,
            };
            processedLegacyDateTimeFields.push(id3);
            jsonFormData.push(innerJson);
          }
        } else {
          // This is to add standard drop down lists eg Salutation.
          // Non-Legacy date input handled below
          var id4 = parseInt(field.id);
          innerJson = {
            Id: id4,
            Value: field.value,
            IsStandard: isStandardField,
            IsRfp: isRfpField,
          };
          jsonFormData.push(innerJson);
        }
        break;
      case "select-multiple":
        var id5 = parseInt(field.id);

        var result = "";
        var options = field && field.options;
        var opt;

        for (var nexti = 0; nexti < options.length; nexti++) {
          opt = options[nexti];

          if (opt.selected) {
            result = result + opt.value + ",";
          }
        }

        innerJson = {
          Id: id5,
          Value: result,
          IsStandard: isStandardField,
          IsRfp: isRfpField,
        };
        jsonFormData.push(innerJson);
        break;
      case "datetime-local":
      case "date":
        var id5 = Math.floor(parseFloat(field.id));
        innerJson = {
          Id: id5,
          SubId: field.id,
          Value: field.value,
          IsStandard: isStandardField,
          IsRfp: isRfpField,
        };
        jsonFormData.push(innerJson);
        break;

      case "checkbox":
        var distPrefix = "list-option-2000000000-";
        var inventoryPrefix = "list-option-2100000000-";
        var connectionPrefix = "list-option-2110000000-";
        var geoCodePrefix = "list-option-100006-";
        var eventCategoriesPrefix = "list-option-100005-";
        var isDistributionList = field.id.startsWith(distPrefix);
        var isInventoryList = field.id.startsWith(inventoryPrefix);
        var isConnectionList = field.id.startsWith(connectionPrefix);
        var isGeoCode = field.id.startsWith(geoCodePrefix);
        var isEventCategory = field.id.startsWith(eventCategoriesPrefix);
        var isMeals =
          parseInt(field.id.substr(0, field.id.indexOf("-"))) ===
          rfpMealFieldId;
        var customFieldDeprefixed = field.id.replace("list-option-", "");
        var customFieldId = parseInt(
          customFieldDeprefixed.substr(0, customFieldDeprefixed.indexOf("-"))
        );
        var isCustomMultiSelect = customMultiSelectIds.includes(customFieldId);
        var customFieldPrefix = `list-option-${customFieldId}-`;
        if (isDistributionList) {
          var listElement = {
            listId: field.id.substring(distPrefix.length),
            isRfp: isRfpField,
          };
          if (field.checked) {
            distributionLists.push(listElement);
          }
        } else if (isInventoryList) {
          // Find inventory item id
          if (field.checked) {
            var listElement = {
              listId: field.getAttribute("data-realvalue"),
              isRfp: isRfpField,
            };
            inventoryItems.push(listElement);
          }
        } else if (isConnectionList) {
          if (field.checked) {
            var listElement = {
              listId: field.getAttribute("data-realvalue"),
              isRfp: isRfpField,
            };
            connectionItems.push(listElement);
          }
        } else if (isMeals) {
          var id6 = parseInt(field.id.substr(0, field.id.indexOf("-")));
          var meal = field.id.substr(
            field.id.indexOf("-") + 1,
            field.id.length
          );
          innerJson = {
            Id: id6,
            SubId: meal,
            Value: field.checked,
            IsStandard: isStandardField,
            IsRfp: isRfpField,
          };
          jsonFormData.push(innerJson);
        } else if (isGeoCode) {
          if (field.checked) {
            var listElement = {
              listId: field.getAttribute("data-realvalue"),
              isRfp: isRfpField,
            };
            geoCodes.push(listElement);
          }
        } else if (isEventCategory) {
          if (field.checked) {
            var listElement = {
              listId: field.getAttribute("data-realvalue"),
              isRfp: isRfpField,
            };
            eventCategories.push(listElement);
          }
        } else if (isCustomMultiSelect) {
          if (field.checked) {
            var fieldValue = field
              .getAttribute("data-realvalue")
              .substring(customFieldPrefix.length);
            var listElement = {
              fieldId: customFieldId,
              listId: fieldValue,
              isRfp: isRfpField,
            };
            customMultiSelections.push(listElement);
          }
        } else {
          innerJson = {
            Id: field.id,
            Value: field.checked.toString(),
            IsStandard: isStandardField,
            IsRfp: isRfpField,
          };
          jsonFormData.push(innerJson);
        }
        break;
    }
  }

  // Concatenate the distribution list ids into a single field
  if (distributionLists.length > 0) {
    var lists = [];
    for (var d = 0; d < distributionLists.length; d++) {
      lists.push(distributionLists[d].listId);
    }
    var distJson = {
      Id: 2000000000,
      value: lists.join(),
      IsStandard: false,
      IsRfp: distributionLists[0].isRfp,
    };
    jsonFormData.push(distJson);
  }

  if (inventoryItems.length > 0) {
    var items = [];
    for (var n = 0; n < inventoryItems.length; n++) {
      items.push(inventoryItems[n].listId);
    }
    var invJson = {
      Id: 2100000000,
      value: items.join(),
      IsStandard: false,
      IsRfp: items[0].isRfp,
    };
    jsonFormData.push(invJson);
  }

  if (connectionItems.length > 0) {
    var items = [];
    for (var n = 0; n < connectionItems.length; n++) {
      items.push(connectionItems[n].listId);
    }
    var invJson = {
      Id: 2110000000,
      value: items.join(),
      IsStandard: false,
      IsRfp: items[0].isRfp,
    };
    jsonFormData.push(invJson);
  }

  if (geoCodes.length > 0) {
    var items = [];
    for (var n = 0; n < geoCodes.length; n++) {
      items.push(geoCodes[n].listId);
    }
    var invJson = {
      Id: 100006,
      value: items.join(),
      IsStandard: false,
      IsRfp: false,
    };
    jsonFormData.push(invJson);
  }

  if (eventCategories.length > 0) {
    var items = [];
    for (var n = 0; n < eventCategories.length; n++) {
      items.push(eventCategories[n].listId);
    }
    var invJson = {
      Id: 100005,
      value: items.join(),
      IsStandard: false,
      IsRfp: false,
    };
    jsonFormData.push(invJson);
  }

  if (customMultiSelections.length > 0) {
    var fields = [];
    for (var n = 0; n < customMultiSelections.length; n++) {
      var field = fields.find((x) => x.Id === customMultiSelections[n].fieldId);
      if (field === undefined) {
        field = {
          Id: customMultiSelections[n].fieldId,
          values: [],
        };
        fields.push(field);
      }
      field.values.push(customMultiSelections[n].listId);
    }

    for (var f = 0; f < fields.length; f++) {
      var invJson = {
        Id: fields[f].Id,
        value: fields[f].values.join("|"),
        IsStandard: false,
        IsRfp: false,
      };

      jsonFormData.push(invJson);
    }
  }

  const json = {
    AccessToken: formAccessToken,
    CaptchaResponse: grecaptchaResponse,
    Form: jsonFormData,
    FormLocation: window.location.href,
  };
  return json;
}

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

function buildInstructions(formObject) {
  var instructions = "";
  for (var i = 0; i < formObject.Properties.length; i++) {
    if (formObject.Properties[i].Id === "Instructions") {
      instructions = formObject.Properties[i].Value;
    }
  }
  if (instructions === null || instructions === undefined) {
    return "";
  } else {
    return `<span class="instructions">${instructions}</span>`;
  }
}

function requiredLabelClass(formObject) {
  if (formObject.Required === true) {
    return ` required`;
  }
  return "";
}
/* Builders */

/* Checkers */
function checkRequired(formObject, inputId) {
  // If the input field is a required field, make a note of its HTML ID and set the data attribute
  // OnLoadCyclone will then register the event handler
  if (formObject.Required === true) {
    requiredFields.push(inputId);
    return `Required data-fieldid=${formObject.Id}`;
  }
  return "";
}

function checkRequiredForLabel(formObject) {
  if (formObject.Required === true) {
    requiredFieldPresent = true;
    return `<span class="required-marker"> *</span>`;
  }
  return "";
}

function checkDateTime() {
  var test = document.getElementById("dateTimeTest");

  if (test.type === "datetime-local") {
    return true;
  }
  return false;
}
function checkDate() {
  var test = document.getElementById("dateTest");

  if (test.type === "date") {
    return true;
  }
  return false;
}

function findJsonObj(data, id) {
  var found = null;
  var index = 0;
  var idAsString = "" + id;

  while (!found && index < data.length) {
    var candidate = data[index];
    if (candidate.Id === id || candidate.Id === idAsString) {
      found = candidate;
    }

    index++;
  }

  return found;
}

function convertToDate(dateString) {
  if (!dateString || dateString.length === 0) return null;

  var dateObject = null;
  if (dateString.length > 10) {
    var reggie = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2})/;
    var dateArray = reggie.exec(dateString);
    dateObject = new Date(
      +dateArray[1],
      +dateArray[2] - 1, // Careful, month starts at 0!
      +dateArray[3],
      +dateArray[4],
      +dateArray[5],
      0
    );
  } else {
    var reggie = /(\d{4})-(\d{2})-(\d{2})/;
    var dateArray = reggie.exec(dateString);
    dateObject = new Date(
      +dateArray[1],
      +dateArray[2] - 1, // Careful, month starts at 0!
      +dateArray[3],
      0,
      0,
      0
    );
  }
  return dateObject;
}

function eventDatesAreValid(jsonFormData) {
  var isValid = true;

  if (jsonFormData && jsonFormData.Form) {
    var fromObj = findJsonObj(jsonFormData.Form, fromIdInt);
    var toObj = findJsonObj(jsonFormData.Form, toIdInt);
    var allDayObj = findJsonObj(jsonFormData.Form, allDayIdInt);

    if (fromObj && toObj) {

      var fromDate = convertToDate(fromObj.Value);
      var toDate = convertToDate(toObj.Value);
      var isAllDay = allDayObj && allDayObj.Value === "true";
      if (fromDate && toDate) {
        
        if (isAllDay) {
          isValid = toDate >= fromDate;
        } else {
          isValid = toDate > fromDate;
        }

        if (!isValid) {
          console.warn("Invalid from to event date.");
          writeErrorMessage(fromIdInt, originalForm.Translations.ValidationFromToDate);
        }
      }
    }
  }

  return isValid;
}

function listsAreValid(form, jsonFormData) {

    var isValid = true;

    if (jsonFormData && jsonFormData.Form) {

        for (var i = 0; i < originalForm.Form.length; i++) {
            var formObject = originalForm.Form[i];

            if (formObject.ControlType === "DistributionList" ||
                formObject.ControlType === "InventoryList" ||
                formObject.ControlType === "ConnectionItemList") { 

                clearErrorMessage(formObject.Id);

                if (formObject.Required) {
                    var postedField = findJsonObj(jsonFormData.Form, formObject.Id);
                    
                    if (postedField == null || postedField.value.length === 0) {
                        isValid = false;
                        writeErrorMessage(formObject.Id, originalForm.Translations.ValidationChooseOption);
                        console.log("Field is required but not set", formObject, postedField);
                    }
                }
             }
        }
    }

    return isValid;
}

function writeErrorMessage(fieldId, text) {
  var message = document.getElementById(`${fieldId}-validation`);
  if (message) {
    message.innerText = text;
    message.hidden = false;
  }
}

function clearErrorMessage(fieldId) {
    var message = document.getElementById(`${fieldId}-validation`);
    if (message) {
        message.innerText = "";
        message.hidden = true;
    }
}

function validateForm(form) {
  let formValid = true;

  for (var i = 0; i < form.elements.length; i++) {
    var field = form.elements[i];
    if (field.validity.valid === false) {
      formValid = false;
      writeErrorMessage(field.id, field.validationMessage);
    }
  }

  return formValid;
}

function validateField(id) {
  var field = document.getElementById(id);
  var message = document.getElementById(`${field.id}-validation`);
  if (field.validity.valid === false) {
    if (message) {
      message.innerText = field.validationMessage;
      message.hidden = false;
    }
    return false;
  } else {
    if (message) {
      message.innerText = "";
      message.hidden = true;
    }
    return true;
  }
}
function checkPlaceholder(formObject) {
  var placeholder = "";
  for (var i = 0; i < formObject.Properties.length; i++) {
    if (formObject.Properties[i].Id === "Placeholder") {
      placeholder = formObject.Properties[i].Value;
    }
  }
  if (placeholder === null || placeholder === undefined) {
    return "";
  } else {
    return `placeholder="${placeholder}"`;
  }
}
/* Checkers*/

/* DateTime Input Handling */
function onLoadCyclone() {
  if (!dateTimeSupported) {
    for (let id of legacyDateTimeFields) {
      getDateElements(id);

      populateDays(monthSelect.value);
      populateYears();
      populateHours();
      populateMinutes();
    }
    for (let id of legacyDateFields) {
      getDateElements(id);

      populateDays(monthSelect.value);
      populateYears();
    }
  }

  // Check for required fields and register their on blur event so we show the validation correctly.
  if (requiredFields.length > 0) {
    for (var i = 0; i < requiredFields.length; i++) {
      document
        .getElementById(requiredFields[i])
        .addEventListener("blur", function () {
          var formObjectId = this.dataset.fieldid;
          validateField(formObjectId);
        });
    }
  }

  initialLoadDropdowns();

  if (recaptchaRequired === false) {
    enableSubmit();
  }
}

function populateDays(month, selectElementId) {
  // delete the current set of <option> elements out of the day <select>, ready for the next set to be injected
  while (daySelect.firstChild) {
    daySelect.removeChild(daySelect.firstChild);
  }

  // Create variable to hold new number of days to inject
  var dayNum;

  // 31 or 30 days?
  if (
    month === "January" ||
    month === "March" ||
    month === "May" ||
    month === "July" ||
    month === "August" ||
    month === "October" ||
    month === "December"
  ) {
    dayNum = 31;
  } else if (
    month === "April" ||
    month === "June" ||
    month === "September" ||
    month === "November"
  ) {
    dayNum = 30;
  } else {
    // If month is February, calculate whether it is a leap year or not
    var year = yearSelect.value;
    var isLeap = new Date(year, 1, 29).getMonth() === 1;
    isLeap ? (dayNum = 29) : (dayNum = 28);
  }

  // inject the right number of new <option> elements into the day <select>
  for (var i = 1; i <= dayNum; i++) {
    var option = document.createElement("option");
    option.textContent = i;
    option.value = i;
    daySelect.appendChild(option);
  }

  var previousDay;
  let j = 0;
  let previousDayRetrieved = false;
  while (j < previousDays.length && !previousDayRetrieved) {
    var d = previousDays[j];
    if (d.id === selectElementId) {
      previousDay = d.selected;
      previousDayRetrieved = true;
    }
    j++;
  }
  // if previous day has already been set, set day selects value
  // to that day, to avoid the day jumping back to 1 when you
  // change the year
  if (previousDay) {
    daySelect.value = previousDay;

    let dayOutsideMonth = false;

    // If the previous day was set to a high number, say 31, and then
    // you chose a month with less total days in it (e.g. February),
    // this part of the code ensures that the highest day available
    // is selected, rather than showing a blank daySelect
    if (daySelect.value === "") {
      daySelect.value = previousDay - 1;
      dayOutsideMonth = true;
    }

    if (daySelect.value === "") {
      daySelect.value = previousDay - 2;
    }

    if (daySelect.value === "") {
      daySelect.value = previousDay - 3;
    }

    if (dayOutsideMonth) {
      dayChange(daySelect);
    }
  }
}

function populateYears() {
  // get this year as a number
  var date = new Date();
  var year = date.getFullYear();

  // Add 20 years before this year first
  for (var i = 20; i > 0; i--) {
    var option = document.createElement("option");
    option.textContent = year + i;
    yearSelect.appendChild(option);
  }

  // Make this year, and the 100 years before it available in the year <select>
  for (var j = 0; j <= 100; j++) {
    var option2 = document.createElement("option");
    option2.textContent = year - j;

    // If this year, make default
    if (j === 0) {
      option2.selected = true;
    }
    yearSelect.appendChild(option2);
  }
}

function populateHours() {
  // populate the hours <select> with the 24 hours of the day
  for (var i = 0; i <= 23; i++) {
    var option = document.createElement("option");
    option.textContent = i < 10 ? "0" + i : i;
    hourSelect.appendChild(option);
  }
}

function populateMinutes() {
  // populate the minutes <select> with the 60 hours of each minute
  for (var i = 0; i <= 59; i++) {
    var option = document.createElement("option");
    option.textContent = i < 10 ? "0" + i : i;
    minuteSelect.appendChild(option);
  }
}

//preserve day selection
var previousDays = [];

// update what day has been set to previously
// see end of populateDays() for usage
function dayChange(selectObject) {
  const id = selectObject.id.substr(0, selectObject.id.indexOf("-"));
  let i = 0;
  let updatedExisting = false;
  while (i < previousDays.length && !updatedExisting) {
    var d = previousDays[i];
    if (d.id === id) {
      d.selected = selectObject.value;
      updatedExisting = true;
    }
    i++;
  }
  if (!updatedExisting) {
    previousDays.push({ id: id, selected: selectObject.value });
  }
}

// when the month or year <select> values are changed, rerun populateDays()
// in case the change affected the number of available days
function dateChange(selectObject) {
  var id = selectObject.id.substr(0, selectObject.id.indexOf("-"));
  getDateElements(id);
  populateDays(monthSelect.value, id);
}

function getDateElements(id) {
  yearSelect = document.getElementById(`${id}-year`);
  monthSelect = document.getElementById(`${id}-month`);
  daySelect = document.getElementById(`${id}-day`);
  hourSelect = document.getElementById(`${id}-hour`);
  minuteSelect = document.getElementById(`${id}-minute`);
}

function getMonthFromString(mon) {
  var d = Date.parse(mon + "1, 2012");
  if (!isNaN(d)) {
    return new Date(d).getMonth() + 1;
  }
  return -1;
}

/* DateTime Input Handling */

getTheForm();

window.dayChange = dayChange;
window.dateChange = dateChange;
window.enableSubmit = enableSubmit;
window.dropdownchange = dropdownchange;
window.checkBoxClicked = checkBoxClicked;
